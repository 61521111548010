<template>
  
  <UiContentNotExists
    v-if="userSettingsData?.id != useAuth().getId()"
  />

  <div 
    v-else-if="userSettingsData?.id && userSettingsDataStatus === 'success'"
    class="pb" 
  >
    <UiTabs 
      :tabs="tabs" 
      :acttab="activeTab"
    />    
    <br>
    <NuxtPage
      :user="userSettingsData"
      :user-id="userSettingsData.id"
    />
  </div>
</template>



<script setup lang="ts"> 

import type { User } from '@/types/user'

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const {$api} = useNuxtApp()

var link = ref<string>(route.params['id'] as string);
var activeTab = ref<string>('profile');



const loadData = async function(userName: string) {   
  const response = await $api.getGrowerByName(userName)  
  return response;  
}


const { status: userSettingsDataStatus,  data: userSettingsData } = await useLazyAsyncData('userSettingsData', async () => await loadData(link.value) as User, {immediate: true, deep: true});


const tabs = computed(() => {
  return useUser().getProfileSettingsTabs(userSettingsData.value)
});
 



</script>

<style scoped>



</style>
